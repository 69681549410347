.eduCard {
    height: 380px;
    background: transparent !important;
    position: relative;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-family: 'Rochester', cursive; */
    font-family: 'Handlee', cursive;
}

.eduCard:hover {
    /* mix-blend-mode: multiply; */
    background-blend-mode: multiply;
}

.eduCardBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    min-height: 380px;
    padding: 0px;
    font-weight: 500;

}

.eduCardBody .iconWrapper {
    --size: 90px;
    height: var(--size);
    width: var(--size);
    box-sizing: border-box;
    background: var(--background-navy);
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    transition: 1s;
    top: 180px;
    font-size: calc(30px + 1.5vw);
    /* border: 3px solid var(--bg-orange) transparent; */
    /* box-shadow: 0 10px 10px rgba(128, 128, 128, 0.3); */
}

.eduCardBody .titleWrapper {
    height: calc(360px - 100px);
    border: 1px solid var(--bg-orange);
    width: 100%;
    border-radius: 4px;
    z-index: 1 !important;
    background: var(--bg-coffee);

}


.titleWrapper:hover~.iconWrapper {
    background: var(--background-navy);
    top: 0px;
}

.eduCardBody .titleWrapper .vt {
    -webkit-text-orientation: upright;
    text-orientation: upright;
    writing-mode: vertical-lr;
    letter-spacing: -3.2px;
    background-color: var(--background-navy);
    color: white;
    text-transform: uppercase;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px !important;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-weight: 600;
}

.textBlue {
    color: rgba(13, 110, 253) !important;
}

@media screen and (max-width: 550px) {
    .eduCard {
        height: auto;
    }

    .eduCardBody {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        /* background-color: navy; */
    }

    .eduCardBody .iconWrapper {
        position: inherit;
        z-index: 9999;
        margin-bottom: 25px;
    }


}