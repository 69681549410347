.piecesContainer {
    width: 302px;
    border: 1px solid var(--bg-orange);
}

.piecesContainer {
    height: 302px;
}

.piecesContainer img {
    max-width: 100px;
    height: 100px;
}

.tableContainer {
    width: 300px;
    height: 300px;

}

.tableContainer .table tr {
    border: 1px solid var(--bg-orange);
}

.tableContainer .table tr td {
    border: 1px solid var(--bg-orange);
}



.tableContainer table tr td {
    width: 100px;
    height: 100px;
}

.tableContainer table tr td img {
    min-width: 100%;
    height: 100px;
}