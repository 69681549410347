@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Handlee&family=Rochester&family=Stalemate&family=Work+Sans&display=swap');

@import url("./root.css");
@import url("./Index.module.css");
@import url("./Puzzel.module.css");

body {
    background: var(--bg-coffee) !important;
    color: var(--text-orange) !important;
    font-family: 'Handlee', cursive !important;
    /* font-family: 'Fredericka the Great', cursive; */
    /* font-family: 'Rochester', cursive; */
    /* font-family: 'Stalemate', cursive; */
    /* font-family: 'Work Sans', sans-serif !important; */
}

.contentCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentWrapper {
    position: relative !important;
    width: 100%;
    height: 100%;
    min-height: calc(400px + 7vw);
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, var(--bg-coffee) 50%, var(--bg-orange) 50%) !important;
    /* mix-blend-mode: difference; */
    padding: 60px 30px !important;
    /* mix-blend-mode: difference; */
}

.contentWrapper .infoText {
    font-size: calc(20px + 2vw);
    color: var(--text-white) !important;
}

.animatedText {
    margin-top: auto;
    font-size: calc(25px + 4.4vw);
    mix-blend-mode: difference;
    font-weight: 800;
    z-index: 1;
    display: block;
    transition: .4s;
    /* height: calc((30px + 5vw) * 2); */
}

.height80 {
    display: block;
    min-height: calc(75px + 3.8vw) !important;

}

.sideStepper {
    position: absolute;
    vertical-align: middle;
    left: 10px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.sideStepper .bubble {
    background-color: var(--bg-coffee);
    border: .5px solid var(--bg-orange);
    width: calc(30px + 1.6vw);
    height: calc(30px + 1.6vw);
    border-radius: 50%;
    opacity: .7;
    transition: .5s;
    font-size: calc(20px + 1vw) !important;
}

.textWhite {
    color: var(--text-white) !important;
    transition: .5s;
}

.sideStepper .bubble:hover {
    opacity: 1;
    background: var(--bg-orange);
    mix-blend-mode: screen;
}

.sideStepper .bubble:hover .textWhite {
    color: var(--text-white);
}

.sideStepper .bubble:hover .hoverWrapper {
    display: flex;
}

.sideStepper .bubble .hoverWrapper {
    padding-left: 10px;
    position: absolute;
    left: 100%;
    display: none;
}

.sideStepper .bubble .hoverWrapper .bubbleHover {
    background: var(--bg-orange);
    color: var(--text-white);
    min-width: 170px;
    min-height: 70px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    text-transform: capitalize;
    font-size: calc(15px + .3vw);
    letter-spacing: 2px;
    font-weight: 700;
    opacity: 1;

}

/* .hoverWrapper:has(ul) {
    padding-bottom: 0px !important;
} */

.bubbleHover:has(ul) {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

.sideStepper .bubble .hoverWrapper ul {
    font-size: calc(12px + .3vw);
    display: flex;
    flex-direction: column;
    gap: 7px;
    list-style: none;
}

.sideStepper .bubble .hoverWrapper ul li::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--text-white);
    display: inline-block;
    margin-left: -25px;
}


iFrame {
    width: 100%;
    height: 300px;
}

.contactUs {
    width: 100%;
    min-height: 300px;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

}

.contactUs iFrame {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 450px;
    left: 0px;
    top: 0px;
    z-index: 0;
    background: var(--bg-orange);
    mix-blend-mode: difference;
    transition: 1s;
}

.contactUs .iFrameLayer {
    position: absolute;
    top: 0px;
    left: 0px;
    min-height: 450px;
    height: 100%;
    width: 100%;
    z-index: 10;
    background: var(--bg-white);
    mix-blend-mode: difference;

}

.contactUs .contactUsForm {
    z-index: 10;
    width: min(400px, 100%);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    font-weight: bold;
    border-radius: 5px;
    background: var(--bg-coffee);
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    transition: .6s;
    border: 5px solid var(--bg-coffee);
}

.anchor {
    color: var(--text-white) !important;
}

.contactUs .icon {
    color: rgba(13, 110, 253) !important;
}

/*
.contactUs .contactUsForm:hover {
    background: var(--text-coffee);
    color: var(--text-orange) !important;
    border: 5px solid var(--bg-orange);
} */


.projectButton {
    display: inline-block;
    justify-content: center;
    align-items: center;
    background: var(--bg-orange) !important;
    color: var(--text-white) !important;
    gap: 10px;
}

.skills {
    background: linear-gradient(90deg, var(--bg-orange) 50%, var(--bg-coffee) 50%);
    min-height: 300px;
    padding-bottom: 30px;
}

.skills .textContent {
    color: var(--text-orange);
    mix-blend-mode: difference;
    padding: 30px calc(0px + 3vw);
    font-family: 'Work Sans', sans-serif !important;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Handlee', cursive;
}


.skillsCardWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(100px + 15vw), 1fr));
    min-width: 170px !important;
    align-items: center;
    gap: 10px;

}

.skillsCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid white;
    justify-self: center;
    width: 170px !important;
    height: 200px;
    border-radius: 85px;
    word-spacing: 200px;
    font-size: calc(35px);
    background: var(--iconColor);
    mix-blend-mode: normal !important;
    color: white;
    box-shadow: 0 0 6px rgba(100, 100, 100, 1);
}

.skillsCard {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    border: 3px solid white;
    justify-self: center;
    width: 140px !important;
    height: 170px;
    border-radius: 85px;
    word-spacing: 200px;
    font-size: calc(35px);
    background: var(--iconColor);
    mix-blend-mode: normal !important;
    color: white;
    box-shadow: 0 0 15px -6px var(--iconColor);
}

.skillsCard span {
    font-size: 50px;
    color: white;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skillsCard section {
    font-size: var(--size);
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*
.textContent:hover {
    color: white;
    text-shadow: 0 0 2px gray;
    mix-blend-mode: screen;
} */

/* experience */
.experienceCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 370px;
}

.experienceCard .title {
    font-weight: 600;
    color: var(--text-white);
    text-decoration-line: underline;
    text-decoration-style: double;
    text-decoration-color: var(--text-white);
    text-underline-offset: 10px;
    text-decoration-thickness: 2px;
    margin-bottom: 20px;
}

.aboutMeHeight {
    font-size: 22px;
}


.blackNwhite {
    --size: 25px;
    width: var(--size);
    height: var(--size);
    background: linear-gradient(90deg, black 50%, white 50%);

    border-radius: 100%;
    box-shadow: 0 0 3px black;
}

.coffeeNOrange {
    --size: 25px;
    width: var(--size);
    height: var(--size);
    background: linear-gradient(90deg, #ee751d 50%, #211d1c 50%) !important;
    border-radius: 100%;
    box-shadow: 0 0 3px black;
}

.greenNpink {
    --size: 25px;
    width: var(--size);
    height: var(--size);
    background: linear-gradient(90deg, rgba(200, 0, 90, 1) 50%, #001b06 50%) !important;
    border-radius: 100%;
    box-shadow: 0 0 3px black;
}

/* experience */