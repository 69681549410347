* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;

}

:root {
    --bg-coffee: #211d1c;
    --bg-orange: #ee751d;
    --bg-white: white;
    --bg-blue: #0d6cf9;

    --text-coffee: #211d1c;
    --text-orange: #ee751d;
    --text-white: white;
    --text-black: black;

    --font1: Stalemate, cursive;
    --font2: Rochester cursive;
    --font3: 'Fredericka the Great', cursive;
}